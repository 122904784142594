















































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator';

@Component
export default class AnalyzerEdit extends Vue {
  @Prop() public id!: number;

  public $store: any;
  public $q: any;
  public $route: any;
  public loading: boolean = true;
  public organisation: any = null;
  public organisationOptions: any = [];
  public analyzer: any = {
    serial: '',
    mac: '',
  };

  public async created() {
    this.$store.dispatch('loadAnalyzer', {analyzerId: this.id})
    .then((response: any) => {
      this.analyzer = response;
      this.organisation = response.organisation;
      this.loading = false;
    })
    .catch((error: any) => {
      this.globalError(error);
      this.loading = false;
    });
    this.loadOrganisations();
  }

  private loadOrganisations() {
    this.$store.dispatch('loadOrganisations', {
      limit: 500,
      sort_by: 'name'})
    .then((response: any) => {
      this.organisationOptions = response.organisations;
    }).catch((error: any) => {
      this.globalError(error);
    });
  }

  private onSubmit() {
    this.loading = true;

    const data: any = {
      payload: {
        organisation_id: this.organisation === null ? null : this.organisation.id,
      },
      analyzerId: this.id,
    };

    const capabilities = this.analyzer.capabilities;

    if (capabilities.includes('edit_name')) {
      data.payload.name = this.analyzer.name;
    }

    if (capabilities.includes('cozyair_v2_mode') && this.analyzer.card_version === 2) {
      data.payload.v2_mode = this.analyzer.v2_mode;
    }

    if (capabilities.includes('measurement_interval')) {
      data.payload.measurement_interval = this.analyzer.measurement_interval;
    }

    if (capabilities.includes('external_fetch')) {
      data.payload.fetch_disabled = this.analyzer.fetch_disabled;
    }

    this.$store.dispatch('editAnalyzer', data)
    .then((response: any) => {
      this.$q.notify({
          message: `${response.serial} a été édité`,
          color: 'teal',
        });
      this.$router.push(`/admin/analyzer/${response.id}`);
      this.loading = false;
    }).catch((error: any) => {
      this.loading = false;
      this.globalError(error);
    });
  }
}
